/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import './variables/_variables.scss';


.dropup {
    position: fixed !important;
}
.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell{
	height: 30px !important;
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
	color: #FF9345 !important;
}

.email-application {
	.content-area-wrapper {
		border: none !important;
	}
}

.dark-layout {
	.email-user-list {
		.ps__thumb-y {
			background-color: rgba(0, 0, 0, 0);
			background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from($primary), to($primary), color-stop(.6,$primary));
		}
	}
}

//reduce el espacio ocupado en el header de dt
.sc-fznKkj {
	white-space: pre-line !important;
}

.rtf--mb, .rtf--ab {
	background-color: $primary !important;
}

.react-dataTable {
	.rdt_TableHead {
	  .rdt_TableHeadRow {
		height: 50px !important;
	  }
	}
}


.react-dataTable {
	.rdt_TableHeadRow input[type='checkbox'],
	.rdt_TableRow input[type='checkbox']:not(#switch-success) {
	  appearance: none;
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  width: 18px;
	  height: 18px;
	  border-radius: 4px;
	  border: 2px solid #747b8d;
	  outline: none;
	  //transition: border-color 0.2s ease-in-out;
	}
  
	.rdt_TableHeadRow input[type='checkbox']:checked,
	.rdt_TableRow input[type='checkbox']:not(#switch-success):checked {
	  background-color: $primary;
	  border-color: $primary;
	}
}

  
.swal2-loader {
	border-top-color: $primary !important; /* Define el color del borde superior del spinner */
	border-bottom-color: $primary !important; /* Define el color del borde inferior del spinner */
}

.content-area-wrapper{
	height: 100% !important;
}


.acc-item{
	background:'#283046' !important;
	margin-bottom: 10px  !important;
	.accordion-button {
		font-size: 16px !important;
		font-weight: bold !important;
	}
}

//---help-----
.help-content {
	font-family: "Montserrat", Helvetica, Arial, serif !important;
}

.help-title {
    font-family: inherit !important;
    font-weight: 500 !important;
    color: #ffffff !important;
	margin-top: 0 !important;
	padding-top: 0 !important;
	//padding-bottom: 0 !important;
	margin-bottom: 0 !important;
	font-family: "Montserrat", Helvetica, Arial, serif !important;
}

.help-subtitle{
	color: #b4b7bd !important;
	font-family: "Montserrat", Helvetica, Arial, serif !important;
}

.help-tab{
	color: #ffffff !important;
}

.help-tab h3{
	font-size: 1.5rem !important;
	padding-top: 0 !important;
	color: #ffffff !important;
	font-family: "Montserrat", Helvetica, Arial, serif !important;
}

.help-tab p{
	color: #b4b7bd !important;
	font-family: "Montserrat", Helvetica, Arial, serif !important;
	margin-bottom: 0;
}

.nav span{
	font-family: "Montserrat", Helvetica, Arial, serif !important;
}

// image upload
.image-square {
	width: 100%;
	height: 185px;
	border: 2px dashed #283046;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .placeholder-text {
	font-size: 18px;
	text-align: center;
  }
  
.file-input {
	position: relative;
	display: inline-block;
	width: 100%;
}
  
  /* Estilo para ocultar el botón de selección de archivos */
.file-input input[type='file'] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

/*width*/
@media (max-width: 648px){
	.w-xs-100{
		width: 100% !important;
	}
}

// apex charts - pieChart
.apexcharts-legend-text {
	color: #ffffff !important;  // Color del texto de la leyenda
	font-size: 15px !important;
}

.apexcharts-pie-label, .apexcharts-datalabels, .apexcharts-datalabel, .apexcharts-datalabel-label, .apexcharts-datalabel-value{
	fill: #ffffff !important; 
}

// modal-sidebar-hide

.modal-sidebar-hide .sidebar{
	display: none;
}

// obligation field
.form-label-required::after {
	content: " *";
	color: $primary;
  }